import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import fundo from "../../images/fundo.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/blob.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import feature from "images/feature1.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = styled.div`
  ${tw`relative `}
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-96 md:h-auto relative`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full `,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-96 w-96 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-96 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;
const Subheading = tw(SubheadingBase)`text-sm text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

export default ({
  subheading = "Desde 2021",
  heading = (
    <>
      Ajudando o seu <wbr />
      inglês a <span tw="text-primary-500">fluir</span>
    </>
  ),
  description = "O Talk All Place é o lugar onde o inglês se transforma em uma jornada de propósito, perseverança e amor pelo idioma. Desde o início de nossas atividades em 2021, dedicamos nossos esforços para proporcionar uma experiência única e enriquecedora a todos os que buscam dominar a língua inglesa. Com um método próprio, abordagem personalizada em aulas particulares ou em grupo, oferecemos um ambiente acolhedor e estimulante. Nosso curso é online, acessível e adaptável, permitindo que o aprendizado aconteça em qualquer lugar, conectado ao ritmo de cada aluno.",
  primaryButtonText = "Inicie sua jornada",
  primaryButtonUrl = "https://api.whatsapp.com/send?phone=5571994119997",
  imageSrc = feature,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects

  return (
    <section id="talk">
      <Container>
        <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
          <ImageColumn css={imageContainerCss}>
            {imageInsideDiv ? (
              <Image imageSrc={imageSrc} css={imageCss} />
            ) : (
              <img src={imageSrc} css={imageCss} alt="" />
            )}
            {imageDecoratorBlob && (
              <DecoratorBlob css={imageDecoratorBlobCss} />
            )}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>

              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            </TextContent>
          </TextColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </Container>
    </section>
  );
};
