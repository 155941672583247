import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import EmailIllustrationSrc from "images/email-illustration.svg";
import { ReactComponent as WhatsappIcon } from "../../images/whatsapp-icon.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as TikTokIcon } from "../../images/tiktok-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram_icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon1.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12  flex-shrink-0 h-80 md:h-auto mx-auto`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const SocialLinksContainer = tw.div`flex flex-wrap mt-10 justify-center text-center md:justify-start md:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-secondary-100 hover:text-gray-500 transition duration-300 mx-2 mb-4 md:mb-0`}
  svg {
    ${tw`w-12 h-12`}
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  margin-top: 4rem;
  ${tw`rounded overflow-hidden`}
  iframe {
    ${tw`w-full h-full rounded rounded-xl bg-black shadow-xl`}
    border: none !important; /* Adiciona border-none para remover a borda */
  }
`;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "",
  heading = (
    <>
      Conecte-se com o Talk
      <wbr /> e deixe seu inglês <span tw="text-primary-500">FLUIR</span>
    </>
  ),
  description = "",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <StyledResponsiveVideoEmbed
            url="https://player.vimeo.com/video/878415071?title=0&portrait=0&byline=0&autoplay=0&responsive=1&badge=0&share=0"
            background="transparent"
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <SocialLinksContainer>
              <SocialLink href="https://api.whatsapp.com/send?phone=5571994119997">
                <WhatsappIcon />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@talkallplace">
                <TikTokIcon />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/talkallplace/">
                <InstagramIcon />
              </SocialLink>
              <SocialLink href="https://www.youtube.com/@talkallplace943">
                <YoutubeIcon />
              </SocialLink>
            </SocialLinksContainer>
            <SubmitButton>Inicie sua jornada</SubmitButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
