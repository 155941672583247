import React from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import imgFaq from "../images/faqs.svg";

function AgencyLandingPage() {
  return (
    <AnimationRevealPage>
      <Hero />
      <MainFeature />
      <MainFeature2 />
      <Features />

      <Testimonial textOnLeft={true} />
      <FAQ
        imageSrc={imgFaq}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={<>Dúvidas Frequentes</>}
      />

      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
}

export default AgencyLandingPage;
