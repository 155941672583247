import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import clau from "../../images/clau.svg";
import emily from "../../images/emily.svg";
import raiane from "../../images/raiane.svg";
import cristiane from "../../images/cristiane.svg";
import micael from "../../images/micael.svg";
import luana from "../../images/luana.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/blob-1.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Subheading = tw(SubheadingBase)`text-center`;

const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-96 w-96  transform -translate-x-2/3 -translate-y-12 `}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-96 w-96 transform translate-x-1/2`}
`;

const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

export default ({
  subheading = "AGORA, SÓ FALTA VOCÊ",
  heading = "Nossos alunos deixaram o inglês fluir",
  description = "Descubra como o Talk All Place se encaixou na vida deles e como o inglês se tornou parte do dia a dia. Não perca tempo, venha fazer parte da família. – a próxima história de sucesso pode ser a sua.",
  testimonials = [
    {
      profileImageSrc: clau,

      quote:
        "“Sabe a sensação de “ agora vai!” é o que eu sinto quando estou nas aulas particulares com a teacher! Agora Vai”",
      customerName: "Claudiana Melo",
    },
    {
      profileImageSrc: emily,

      quote:
        "“...recomendo o Talk, por ser um espaço muito acolhedor, criativo, prático e incentivador. The Talk All Place is the best choice for a new language. Amo muitoooo, super recomendo!❤”",
      customerName: "Emily Rios",
    },
    {
      profileImageSrc: raiane,

      quote:
        "“O Talk apareceu na minha vida de forma inesperada e posso dizer que foi a melhor coisa, me fez acreditar que aprender inglês é possível... GRATIDÃO é a palavra de ordem com toda certeza!”",
      customerName: "Raiane Reis",
    },
    {
      profileImageSrc: cristiane,

      quote:
        "“Sou aluna a pouco tempo porém estou gostando muito das aulas e tenho certeza que em breve estarei fluente em inglês. Foco , força e fé !”",
      customerName: "Cristiane Rocha",
    },
    {
      profileImageSrc: micael,

      quote:
        "“To aprendendo muito inglês. A professora é alegre, prestativa e nos incentiva cada dia aprendermos mais.”",
      customerName: "Micael Cruz",
    },
    {
      profileImageSrc: luana,

      quote:
        "“...Ao decidir fazer o Talk, a metodologia aplicada pela teacher me fez compreender um universo que eu jamais havia visto. Estou caminhando para o segundo ano do curso e afirmo: tudo o que eu aprendi foi aqui!”",
      customerName: "Luana Meireles",
    },
  ],
}) => {
  const settings = {
    className: "testimonial-slider",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3, // Sempre exibe 3 depoimentos
    slidesToScroll: 3, // Avança de 3 em 3
    responsive: [
      {
        breakpoint: 768, // Define o breakpoint para telas menores
        settings: {
          slidesToShow: 1, // Mostra 1 depoimento
          slidesToScroll: 1, // Avança de 1 em 1
        },
      },
    ],
  };

  return (
    <section id="testemunhas">
      <Container>
        <ContentWithPaddingXl>
          <Heading>{heading}</Heading>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Description>{description}</Description>

          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <TestimonialContainer key={index}>
                <Testimonial>
                  <Image src={testimonial.profileImageSrc} />
                  <Quote>"{testimonial.quote}"</Quote>
                  <CustomerName>- {testimonial.customerName}</CustomerName>
                </Testimonial>
              </TestimonialContainer>
            ))}
          </Slider>
        </ContentWithPaddingXl>

        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </Container>
    </section>
  );
};
